//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventBus from '@/shared/scripts/eventbus';
import { TabIds } from './tabIds';

export default {
  name: 'CheckoutTabs',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    initialTab: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeTab: this.initialTab,
    };
  },
  mounted() {
    EventBus.$on('checkoutNextTab', () => {
      this.nextTab();
    });

    EventBus.$on('setTab', (tabId) => {
      this.setTab(tabId);
    });
  },
  computed: {
    activeTabIndex() {
      return this.tabs.indexOf(this.activeTab);
    },
  },
  methods: {
    visitedTabs(index) {
      const tabs = [0, 1, 2, 3];
      const visited = String(tabs).split(this.activeTabIndex)[0].split(',').filter(Boolean);
      return visited.map(s => Number(s)).includes(index);
    },
    setTabindex(tab) {
      // controls flow when pressing Tab key - https://webaim.org/techniques/keyboard/tabindex
      return this.activeTab.id === tab ? 0 : -1;
    },
    nextTab() {
      if (process.client) {
        this.activeTab = this.tabs[this.activeTabIndex + 1];
        window.scrollTo(0, 0);
      }
    },
    prevTab(index) {
      if (this.activeTabIndex > index) {
        this.activeTab = this.tabs[index];
      }
    },
    setTab(tabId) {
      this.activeTab = this.tabs.find(tab => tab.id === tabId);
    },
    handleKeyUp(event, index) {
      switch (event.key) {
      case 'ArrowLeft':
        this.setTabFocus(index);
        break;
      case 'Enter':
      case 'Space':
        this.activeTab = this.tabs[index];
        break;
      default:
        break;
      }
    },
    setTabFocus(index) {
      this.$nextTick(() => {
        this.$refs[`tab-${index - 1}`][0].focus();
      });
    },
    transactionComplete() {
      this.$router.push({ path: '/shop/confirmation/' });
    },
  },
  watch: {
    activeTab(newVal) {
      this.$emit('inPaymentTab', newVal.id === TabIds.PAYMENT || newVal.id === TabIds.DELIVERY);
    },
  },
};
